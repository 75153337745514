import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyB5x_axyiDRvdNoP54WrPaPLPqt4WzGK5Q',
  authDomain: 'sentiment-analyzer-db.firebaseapp.com',
  projectId: 'sentiment-analyzer-db',
  storageBucket: 'sentiment-analyzer-db.appspot.com',
  messagingSenderId: '481244044453',
  appId: '1:481244044453:web:e83ad68b08b93127a3c14c',
  measurementId: 'G-3GW6YSLX4T',
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);