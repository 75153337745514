import axios from 'axios';

const API_URL = 'https://sentiment-analyzer.stillprofit.in/api';

// Custom error types
class APIError extends Error {
  constructor(message, status, data) {
    super(message);
    this.name = 'APIError';
    this.status = status;
    this.data = data;
  }
}

class NetworkError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NetworkError';
  }
}

// Create axios instance
const api = axios.create({
  baseURL: API_URL,
  timeout: 10000,
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    // You can add any request modifications here, like adding auth tokens
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API Error:', error.response.data);
      throw new APIError(
        error.response.data.message || 'An error occurred',
        error.response.status,
        error.response.data
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Network Error:', error.request);
      throw new NetworkError('No response received from server');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Request Error:', error.message);
      throw new Error('Error setting up the request');
    }
  }
);

// Helper function to retry failed requests
const retryRequest = async (apiCall, retries = 3, delay = 1000) => {
  try {
    return await apiCall();
  } catch (error) {
    if (retries > 0 && error instanceof NetworkError) {
      await new Promise(resolve => setTimeout(resolve, delay));
      return retryRequest(apiCall, retries - 1, delay * 2);
    }
    throw error;
  }
};

// API functions
export const getRelatedNews = async (query) => {
  return retryRequest(async () => {
    const response = await api.get(`/related_news?query=${query}`);
    return response.data;
  });
};

export const analyzeText = async (text) => {
  return retryRequest(async () => {
    const response = await api.post('/analyze', { text });
    return response.data;
  });
};

export const analyzeUrl = async (url) => {
  return retryRequest(async () => {
    const response = await api.post('/analyze', { url });
    return response.data;
  });
};

export const getAutocompleteSuggestions = async (query) => {
  return retryRequest(async () => {
    const response = await api.get(`/autocomplete?query=${query}`);
    return response.data;
  });
};

// Error handling middleware
export const handleAPIError = (error, fallbackMessage = 'An unexpected error occurred') => {
  if (error instanceof APIError) {
    // Handle specific API errors
    switch (error.status) {
      case 400:
        return `Bad request: ${error.data.message || fallbackMessage}`;
      case 401:
        return 'Unauthorized: Please log in again';
      case 403:
        return 'Forbidden: You do not have permission to access this resource';
      case 404:
        return 'Not found: The requested resource does not exist';
      case 500:
        return 'Server error: Please try again later';
      default:
        return `Error: ${error.data.message || fallbackMessage}`;
    }
  } else if (error instanceof NetworkError) {
    return 'Network error: Please check your internet connection';
  } else {
    return fallbackMessage;
  }
};
