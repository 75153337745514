import styled, { createGlobalStyle, css } from 'styled-components';
import { Link } from 'react-router-dom';

const theme = {
  colors: {
    primary: '#6C63FF',
    secondary: '#1E1E2E',
    background: '#0F1624',
    text: '#FFFFFF',
    textSecondary: '#B0B0B0',
    error: '#FF3860',
    errorBackground: '#FEECF0',
    border: '#3D3D5C',
    success: '#28A745',
    successBackground: '#E9F7EF',
  },
  fonts: {
    primary: "'Poppins', sans-serif",
    secondary: "'Roboto', sans-serif",
  },
  fontSizes: {
    small: '0.875rem',
    medium: '1rem',
    large: '1.25rem',
    xlarge: '1.5rem',
    xxlarge: '2rem',
  },
  mediaQueries: {
    mobile: '(max-width: 768px)',
    tablet: '(min-width: 769px) and (max-width: 1024px)',
    desktop: '(min-width: 1025px)',
    largeDesktop: '(min-width: 1441px)',
  },
};

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

  body {
    margin: 0;
    font-family: ${theme.fonts.primary};
    background: linear-gradient(135deg, #833ab4 0%, #fd1d1d 50%, #fcb045 100%);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.text};
    padding: 20px;
    box-sizing: border-box;
    transition: background 0.3s ease, color 0.3s ease;
  }
`;

const mediaQuery = (breakpoint, inner) => css`
  @media ${theme.mediaQueries[breakpoint]} {
    ${inner};
  }
`;

export const Card = styled.div`
  background: ${theme.colors.secondary};
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  ${mediaQuery('mobile', `
    padding: 20px;
    max-width: 90%;
  `)}
  ${mediaQuery('tablet', `
    padding: 30px;
    max-width: 80%;
  `)}
  ${mediaQuery('desktop', `
    padding: 40px;
    max-width: 400px;
  `)}
  ${mediaQuery('largeDesktop', `
    max-width: 500px;
  `)}
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.7);
  }
`;

export const Logo = styled.div`
  background: ${theme.colors.primary};
  color: ${theme.colors.text};
  width: 60px;
  height: 60px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${theme.fontSizes.xlarge};
  font-weight: 700;
  margin-bottom: 30px;
  transition: background 0.3s ease, color 0.3s ease;
`;

export const Title = styled.h1`
  font-size: ${theme.fontSizes.xxlarge};
  font-weight: 600;
  margin-bottom: 15px;
  color: ${theme.colors.text};
  transition: color 0.3s ease;
`;

export const Subtitle = styled.p`
  font-size: ${theme.fontSizes.medium};
  color: ${theme.colors.textSecondary};
  margin-bottom: 30px;
  transition: color 0.3s ease;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const InputGroup = styled.div`
  position: relative;
  margin-bottom: 25px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: ${theme.colors.text};
  font-size: ${theme.fontSizes.small};
  font-weight: 500;
  transition: color 0.3s ease;
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px 40px 12px 15px;
  background-color: ${theme.colors.background};
  border: 2px solid ${theme.colors.border};
  border-radius: 10px;
  font-size: ${theme.fontSizes.medium};
  color: ${theme.colors.text};
  transition: border-color 0.3s ease, background 0.3s ease, color 0.3s ease;
  &:focus {
    outline: none;
    border-color: ${theme.colors.primary};
  }
  &:invalid {
    border-color: ${theme.colors.error};
  }
  &:valid {
    border-color: ${theme.colors.success};
  }
  ${mediaQuery('mobile', `
    padding: 10px 30px 10px 10px;
    font-size: ${theme.fontSizes.small};
  `)}
`;

export const Icon = styled.span`
  position: absolute;
  right: 15px;
  top: 38px;
  color: ${theme.colors.textSecondary};
  transition: color 0.3s ease;
`;

export const Button = styled.button`
  background: ${theme.colors.primary};
  color: ${theme.colors.text};
  border: none;
  padding: 14px;
  border-radius: 10px;
  font-size: ${theme.fontSizes.medium};
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  transition: background 0.3s ease, color 0.3s ease, transform 0.2s ease;
  &:hover {
    background: #5a52cc;
    transform: translateY(-2px);
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  ${mediaQuery('mobile', `
    padding: 12px;
    font-size: ${theme.fontSizes.small};
  `)}
`;

export const LinkText = styled.p`
  text-align: center;
  margin: 20px 0;
  color: ${theme.colors.textSecondary};
  font-size: ${theme.fontSizes.small};
  transition: color 0.3s ease;
`;

export const StyledLink = styled(Link)`
  color: ${theme.colors.primary};
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  &:hover {
    text-decoration: underline;
  }
`;

export const GoogleButton = styled(Button)`
  background: ${theme.colors.background};
  border: 2px solid ${theme.colors.primary};
  color: ${theme.colors.primary};
  &:hover {
    background: ${theme.colors.primary};
    color: ${theme.colors.text};
  }
`;

export const RememberForgot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: ${theme.fontSizes.small};
`;

export const Checkbox = styled.input`
  margin-right: 5px;
`;

export const PasswordStrength = styled.div`
  height: 4px;
  background: ${theme.colors.border};
  margin-top: 5px;
  border-radius: 2px;
  overflow: hidden;
  transition: background 0.3s ease;
`;

export const StrengthIndicator = styled.div`
  height: 100%;
  width: ${props => props.strength}%;
  background: linear-gradient(to right, #ff4757, #ff6b81, #feca57, #48dbfb, #ff9ff3);
  transition: width 0.3s ease;
`;

export const StrengthText = styled.p`
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.textSecondary};
  margin-top: 5px;
  transition: color 0.3s ease;
`;

export const ErrorMessage = styled.div`
  color: ${theme.colors.error};
  background-color: ${theme.colors.errorBackground};
  border: 1px solid ${theme.colors.error};
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  font-size: ${theme.fontSizes.small};
  transition: background 0.3s ease, color 0.3s ease, border-color 0.3s ease;
`;

export const Select = styled.select`
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 2px solid ${theme.colors.border};
  border-radius: 10px;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  font-size: ${theme.fontSizes.medium};
  transition: border-color 0.3s ease, background 0.3s ease, color 0.3s ease;
  &:focus {
    outline: none;
    border-color: ${theme.colors.primary};
  }
  &:invalid {
    border-color: ${theme.colors.error};
  }
  &:valid {
    border-color: ${theme.colors.success};
  }
`;

export const PriceContainer = styled.div`
  background-color: ${theme.colors.background};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  transition: background 0.3s ease;
`;

export const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const PriceLabel = styled.span`
  color: ${theme.colors.textSecondary};
  font-size: ${theme.fontSizes.medium};
  transition: color 0.3s ease;
`;

export const Price = styled.span`
  color: ${theme.colors.text};
  font-weight: 600;
  font-size: ${theme.fontSizes.medium};
  transition: color 0.3s ease;
`;

export const LaunchPrice = styled(Price)`
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes.large};
  transition: color 0.3s ease;
`;
