// useError.js
import { useState, useCallback } from 'react';

const useError = () => {
  const [error, setError] = useState(null);

  const handleError = useCallback((error) => {
    setError(error.message || 'An unexpected error occurred');
    // You could also log the error to an error reporting service here
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return { error, handleError, clearError };
};

export default useError;