import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const ModalContent = styled.div`
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
`;

const TermsModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Terms and Conditions"
      ariaHideApp={false}
    >
      <ModalContent>
        <h2>Terms and Conditions</h2>
        <p>Your terms and conditions content here...</p>
        <button onClick={onRequestClose}>Close</button>
      </ModalContent>
    </Modal>
  );
};

export default TermsModal;
