import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebase';
import { FaEnvelope, FaArrowRight } from 'react-icons/fa';
import {
  GlobalStyle,
  Card,
  Logo,
  Title,
  Subtitle,
  Form,
  InputGroup,
  Label,
  Input,
  Icon,
  Button,
  LinkText,
  StyledLink,
  ErrorMessage,
} from './SharedAuthStyles';
import BackButton from './BackButton';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [lastRequestTime, setLastRequestTime] = useState(0);
  const [requestCount, setRequestCount] = useState(0);

  const handleSubmit = async e => {
    e.preventDefault();
    const now = Date.now();
    const timeSinceLastRequest = now - lastRequestTime;

    // Rate limiting: Allow only 1 request per 60 seconds
    if (timeSinceLastRequest < 60000 && requestCount >= 1) {
      setError('Please wait before sending another request.');
      return;
    }

    setError('');
    setSuccess('');
    setIsLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess('Password reset email sent. Check your inbox and follow the instructions.');
      setRequestCount(requestCount + 1);
      setLastRequestTime(now);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <GlobalStyle />
      <BackButton />
      <Card>
        <Logo>S</Logo>
        <Title>Forgot Password?</Title>
        <Subtitle>Enter your email to reset your password.</Subtitle>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && <div style={{ color: 'green' }}>{success}</div>}
        <Form onSubmit={handleSubmit}>
          <InputGroup>
            <Label>Email Address</Label>
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
            <Icon>
              <FaEnvelope />
            </Icon>
          </InputGroup>
          <Button type="submit" disabled={isLoading}>
            {isLoading ? 'Sending...' : 'Reset Password'}
            {!isLoading && <FaArrowRight style={{ marginLeft: '10px' }} />}
          </Button>
        </Form>
        <LinkText>
          Remember your password? <StyledLink to="/signin">Sign In</StyledLink>
        </LinkText>
      </Card>
    </>
  );
};

export default ForgotPasswordPage;