// App.js
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';  // Correctly import Router, Routes, and Route
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { ref, get } from "firebase/database";
import { auth, db } from './firebase';
import * as api from './api';
import useError from './useError';
import ErrorBoundary from './ErrorBoundary';
import SignInPage from './SignInPage';  // Import SignInPage component
import RegistrationForm from './RegistrationForm';  // Import RegistrationForm component
import ForgotPasswordPage from './ForgotPasswordPage';  // Import ForgotPasswordPage component
import Spline from '@splinetool/react-spline';
import { 
  FaChevronDown, FaBars, FaTimes, 
  FaLanguage, FaFileAlt, FaChartBar, FaInfoCircle, FaQuestionCircle, 
  FaFacebookF, FaTwitter, FaLinkedinIn, FaGithub
} from 'react-icons/fa';
import './App.css';
import './index.css';

function App() {
  const API_URL = '/api';
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputType, setInputType] = useState('news');
  const [input, setInput] = useState('');
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [relatedNews, setRelatedNews] = useState([]);
  const [showTutorial, setShowTutorial] = useState(false);
  const [activeFaq, setActiveFaq] = useState(null);
  const [activeCategory, setActiveCategory] = useState('General');
  const [hasAnalyzed, setHasAnalyzed] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [user, setUser] = useState(null);
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [userSubscription, setUserSubscription] = useState(null);
  const [initialAuthCheckDone, setInitialAuthCheckDone] = useState(false);

  const navigate = useNavigate();
  const { error, handleError, clearError } = useError();

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
  
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
  
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        try {
          const userRef = ref(db, `users/${currentUser.uid}`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            setUserSubscription(snapshot.val().subscriptionActive);
          } else {
            setUserSubscription(false);
          }
        } catch (error) {
          handleError(new Error('Failed to fetch user data'));
        }
      } else {
        setUserSubscription(null);
      }
      setInitialAuthCheckDone(true);
    });
    return unsubscribe;
  }, [handleError]);

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.pageYOffset > 300);
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (results) {
      setTimeout(() => {
        const dashboardElement = document.querySelector('.dashboard');
        if (dashboardElement) {
          window.scrollTo({
            top: dashboardElement.offsetTop - 20,
            behavior: 'smooth'
          });
        }
      }, 100);
    }
  }, [results]);

  useEffect(() => {
    if (hasAnalyzed && results) {
      const resultSection = document.querySelector('.analysis-results');
      if (resultSection) {
        resultSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [hasAnalyzed, results]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (!isOnline) {
      handleError(new Error("You are offline. Please check your internet connection and try again."));
      return;
    }
    if (!user) {
      setShowAuthPopup(true);
      return;
    }
    if (!userSubscription) {
      handleError(new Error("Please complete your payment to access this feature."));
      navigate('/signup');
      return;
    }
    setLoading(true);
    clearError();
    try {
      let response;
      if (inputType === 'news') {
        response = await api.getRelatedNews(input);
        const sortedNews = response.sort((a, b) => new Date(b.pub_date) - new Date(a.pub_date));
        setResults(sortedNews);
      } else {
        response = await (inputType === 'text' ? api.analyzeText(input) : api.analyzeUrl(input));
        setResults(response);
      }
      setHasAnalyzed(true);
  
      if (inputType !== 'news') {
        const newsResponse = await api.getRelatedNews(input);
        const sortedRelatedNews = newsResponse.sort((a, b) => new Date(b.pub_date) - new Date(a.pub_date));
        setRelatedNews(sortedRelatedNews);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, [inputType, input, user, userSubscription, isOnline, handleError, clearError, navigate]);

  const fetchSuggestions = useCallback(async (value) => {
    if (value.length > 0) {
      try {
        const suggestions = await api.getAutocompleteSuggestions(value);
        setSuggestions(suggestions);
      } catch (error) {
        handleError(error);
      }
    } else {
      setSuggestions([]);
    }
  }, [handleError]);

  const handleInputChange = useCallback((e) => {
    const value = e.target.value;
    setInput(value);
    if (inputType === 'news') {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  }, [inputType, fetchSuggestions]);

  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion.SYMBOL);
    setSuggestions([]);
    handleSubmit(new Event('submit'));
  };

  const handleInputTypeChange = (type) => {
    setInputType(type);
    const inputSection = document.querySelector('.input-section');
    inputSection.classList.add('fade');
    setTimeout(() => {
      setInput('');
      setResults(null);
      setSuggestions([]);
      setRelatedNews([]);
      if (type === 'news') {
        handleSubmit(new Event('submit'));
      }
      inputSection.classList.remove('fade');
    }, 300);
  };

  const getPlaceholder = () => {
    switch (inputType) {
      case 'text': return 'Enter your text here...';
      case 'url': return 'Enter a URL to analyze...';
      case 'news': return 'Enter a news query...';
      default: return 'Enter your input here...';
    }
  };

  const getInputDescription = () => {
    switch (inputType) {
      case 'text': return 'Analyze the sentiment of any text you enter.';
      case 'url': return 'Analyze the sentiment of a webpage by entering its URL.';
      case 'news': return 'Search for news articles and analyze their sentiment.';
      default: return 'Choose an input type and start analyzing!';
    }
  };

  const renderSentimentBars = (sentiments, confidences) => {
    const sortedSentiments = sentiments.map((sentiment, index) => ({
      sentiment,
      confidence: confidences[index]
    })).sort((a, b) => b.confidence - a.confidence);

    return (
      <div className="sentiment-bars">
        {sortedSentiments.map(({ sentiment, confidence }, index) => (
          <div key={index} className={`sentiment-bar ${sentiment.toLowerCase()}`}>
            <span>{sentiment}</span>
            <div className="bar-container">
              <div className="bar" style={{ width: `${confidence * 100}%` }}></div>
            </div>
            <span>{(confidence * 100).toFixed(2)}%</span>
          </div>
        ))}
      </div>
    );
  };

  const renderKeywords = (keywords) => (
    <div className="keywords">
      {keywords.map((keyword, index) => (
        <span key={index} className="keyword">{keyword}</span>
      ))}
    </div>
  );

  const renderNewsItem = (item, index) => {
    const sentiment = item.sentiments[0].toLowerCase();
    const confidenceScore = (item.confidences[0] * 100).toFixed(2);
    return (
      <div key={index} className={`news-item ${sentiment}`}>
        <h3>{item.title}</h3>
        <p className="sentiment-label">{sentiment}</p>
        <p className="confidence-score">{confidenceScore}%</p>
        <p className="date">{new Date(item.pub_date).toLocaleString()}</p>
        {renderKeywords(item.keywords)}
        <a href={item.link} target="_blank" rel="noopener noreferrer" className="read-more-btn">
          Read More
        </a>
      </div>
    );
  };

  const renderSuggestions = () => {
    if (suggestions.length > 0) {
      return (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion.SYMBOL} - {suggestion['NAME OF COMPANY']}
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const renderDashboard = () => {
    if (!results || results.error) return null;

    if (inputType === 'news' && Array.isArray(results)) {
      if (results.length === 0) {
        return <div className="no-results">No news results found.</div>;
      }
      return (
        <div className="news-results">
          <h2>News Analysis Results</h2>
          <div className="news-grid">
            {results.map(renderNewsItem)}
          </div>
        </div>
      );
    }

    if (typeof results === 'object' && !results.error) {
      const { sentiments, confidences, keywords } = results;
      if (!sentiments || !confidences || sentiments.length === 0 || confidences.length === 0) {
        return <div className="error-message">No sentiment data available</div>;
      }
      const primarySentiment = sentiments[0];
      const primaryConfidence = confidences[0];

      return (
        <div className="dashboard">
          <h2>Sentiment Analysis Dashboard</h2>
          <div className="dashboard-grid">
            <div className="card primary-sentiment">
              <h3><FaChartBar /> Primary Sentiment</h3>
              <div className="sentiment-gauge">
                <div className={`gauge ${primarySentiment.toLowerCase()}`} style={{ '--percentage': `${primaryConfidence * 100}%` }}>
                  <div className="gauge-body">
                    <div className="gauge-fill"></div>
                    <div className="gauge-cover">{(primaryConfidence * 100).toFixed(0)}%</div>
                  </div>
                </div>
              </div>
              <p className="sentiment-label">{primarySentiment}</p>
            </div>
            <div className="card sentiment-distribution">
              <h3><FaChartBar /> Sentiment Distribution</h3>
              {renderSentimentBars(sentiments, confidences)}
            </div>
            <div className="card keywords">
              <h3><FaFileAlt /> Keywords</h3>
              {renderKeywords(keywords)}
            </div>
            <div className="card analysis-details">
              <h3><FaInfoCircle /> Analysis Details</h3>
              <p><FaLanguage /> <strong>Language:</strong> English</p>
              <p><FaFileAlt /> <strong>Analysis Type:</strong> {inputType === 'text' ? 'Text Input' : 'URL Content'}</p>
              {inputType === 'text' && <p><strong>Word Count:</strong> {input.split(' ').length}</p>}
              <p><strong>Primary Sentiment:</strong> {primarySentiment}</p>
              <p><strong>Confidence:</strong> {(primaryConfidence * 100).toFixed(2)}%</p>
              {inputType === 'url' && results.headline && (
                <>
                  <p className="headline"><strong>Headline:</strong> {results.headline}</p>
                  <a href={input} target="_blank" rel="noopener noreferrer" className="read-more-btn">
                    Read Full Article
                  </a>
                </>
              )}
            </div>
          </div>
          {relatedNews.length > 0 && (
            <div className="related-news">
              <h3>Related News</h3>
              <div className="news-grid">
                {relatedNews.map(renderNewsItem)}
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return <div className="error-message">{results.error || 'An unexpected error occurred'}</div>;
    }
  };

  const faqData = {
    General: [
      {
        question: "What is the accuracy rate of StillProfit's sentiment analysis model, and how do you ensure continuous improvement?",
        answer: "Our sentiment analysis model, tailored specifically for financial contexts, achieves a high accuracy rate that is continually refined through advanced machine learning techniques. While the model excels in financial sentiment analysis, its performance may vary for generic text inputs. We are committed to enhancing our model's capabilities through ongoing research and development."
      },
      {
        question: "What languages does StillProfit's sentiment analysis currently support, and what are your plans for expansion?",
        answer: "Currently, our sentiment analysis service is available in English. We are actively working on expanding our language support to include major global languages, with plans to introduce Spanish, French, German, and Mandarin in the near future."
      },
      {
        question: "How does StillProfit specifically benefit users in making investment decisions?",
        answer: "StillProfit empowers users with the insights needed to make informed investment decisions. By providing real-time sentiment analysis of specific stocks and financial news, users can gauge market sentiment and stay ahead in their investment strategies."
      }
    ],
    Pricing: [
      {
        question: "What are the details of your Basic and Premium plans, including the price and key features?",
        answer: "Our Basic Plan is available at an introductory price of Rs 199, allowing users to analyze sentiment for NSE-listed stocks by inputting text or URLs. We are planning to introduce additional features such as technical sentiment analysis, financial sentiment analysis, and news reaction over price, which will be part of our Premium Plan priced at Rs 999. Current Basic Plan users will receive a flat 50% discount on the Premium Plan when it launches by the end of 2024."
      },
      {
        question: "Do you offer a free trial, and if so, what is the duration?",
        answer: "While we do not offer a free trial at the moment, our introductory pricing of Rs 199 for the Basic Plan is a special launch offer, significantly lower than the MRP of Rs 499. This offer allows users to experience the value of our service at a reduced cost."
      }
    ],
    Features: [
      {
        question: "What types of data sources can your app analyze for sentiment analysis?",
        answer: "Currently, StillProfit supports sentiment analysis for text inputs. We are actively expanding our capabilities to include analysis of other data sources such as URLs and financial documents."
      },
      {
        question: "Can users integrate your app with other tools, and what kind of integrations are available?",
        answer: "API services for integrating StillProfit with other tools are planned for the near future. This will enable seamless integration with popular investment and analytics platforms, enhancing the utility of our service."
      }
    ],
    Support: [
      {
        question: "What support channels do you offer, and what are the support hours?",
        answer: "Our app is available 24/7, with the exception of daily maintenance periods from 12 AM to 6 AM. During these hours, our support team is still available to address any urgent issues via email and chat."
      },
      {
        question: "How do you ensure the security and privacy of user data?",
        answer: "We prioritize the security and privacy of user data. By leveraging Firebase's robust security measures, we ensure that all user information is encrypted and protected. We adhere to strict data protection standards and do not share user data with third parties, maintaining complete confidentiality."
      }
    ]
};

if (!initialAuthCheckDone) {
  return <div>Loading...</div>;
}

  return (
    <ErrorBoundary>
    <div className="App">
      {!isOnline && (
      <div className="offline-message">
        You are currently offline. Some features may be unavailable.
      </div>
    )}
      <Spline scene="https://prod.spline.design/TCGg3vooMtV8Zy2m/scene.splinecode" className='spline-background' />
      <header>
        <div className="logo">StillProfit</div>
        <button className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)} aria-label={isMenuOpen ? "Close menu" : "Open menu"}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
        <nav className={isMenuOpen ? 'active' : ''}>
          <a href="#overview">Overview</a>
          <a href="#features">Features</a>
          <a href="#roadmap">Roadmap</a>
          <a href="#faq">FAQ</a>
          {user ? (
            <button className="cta-button" onClick={() => auth.signOut()}>Logout</button>
          ) : (
            <button className="cta-button" onClick={() => navigate('/signin')}>Get Started →</button>
          )}
        </nav>
      </header>

      <main>
      {userSubscription === false && (
          <div className="subscription-alert">
            Your subscription is not active. Please complete your payment to access all features.
            <button onClick={() => navigate('/signup')}>Complete Payment</button>
          </div>
          )}
        <div className="hero">
          <div className="hero-content">
            <div className="ai-icon">
              <span className="glow"></span>
              <span className="icon">🤖</span>
            </div>
            <h1 className='heroText'>Sentiment Analysis <span className="highlight">Beyond Imagination</span></h1>
            <p>One analysis away from market insights.</p>
            <div className="input-section">
              <div className="input-type-selector">
                <button
                  className={`type-button ${inputType === 'news' ? 'active' : ''}`}
                  onClick={() => handleInputTypeChange('news')}
                >
                  News
                </button>
                <button
                  className={`type-button ${inputType === 'text' ? 'active' : ''}`}
                  onClick={() => handleInputTypeChange('text')}
                >
                  Text
                </button>
                <button
                  className={`type-button ${inputType === 'url' ? 'active' : ''}`}
                  onClick={() => handleInputTypeChange('url')}
                >
                  URL
                </button>
              </div>
              <div className="input-container">
                <input
                  type="text"
                  value={input}
                  onChange={handleInputChange}
                  onKeyPress={(e) => e.key === 'Enter' && handleSubmit(e)}
                  placeholder={getPlaceholder()}
                  aria-label={`Enter ${inputType} for analysis`}
                />
                <button 
                  className="analyze-button" 
                  onClick={handleSubmit} 
                  disabled={loading}
                  aria-label="Analyze input"
                >
                  {loading ? 'Analyzing...' : 'Analyze'}
                </button>
              </div>
              {renderSuggestions()}
              <p className="input-description">
                {getInputDescription()}
              </p>
            </div>
          </div>
        </div>

        <div className="analysis-results">
          {loading ? (
            <div className="loading-container">
              <div className="loading-animation">
                <div className="pulse"></div>
                <div className="pulse"></div>
                <div className="pulse"></div>
              </div>
              <p>Analyzing...</p>
            </div>
          ) : (
            renderDashboard()
          )}
        </div>

        {showBackToTop && (
          <button 
            className="back-to-top" 
            onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
          >
            ↑
          </button>
        )}

        {/* FAQ Section */}
        <section id="faq" className="faq-section">
          <h2>Frequently Asked Questions</h2>
          <p>We're here to help with any questions you have about StillProfit's features and capabilities.</p>
          
          <div className="faq-categories">
            {Object.keys(faqData).map((category) => (
              <button 
                key={category}
                className={`faq-category ${activeCategory === category ? 'active' : ''}`}
                onClick={() => setActiveCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>

          <div className="faq-list">
            {faqData[activeCategory].map((faq, index) => (
              <div 
                key={index} 
                className={`faq-item ${activeFaq === index ? 'active' : ''}`}
                onClick={() => setActiveFaq(activeFaq === index ? null : index)}
              >
                <div className="faq-question">
                  <h3>{faq.question}</h3>
                  <FaChevronDown className="faq-icon" />
                </div>
                <p className="faq-answer">{faq.answer}</p>
              </div>
            ))}
          </div>
        </section>
      
      </main>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>StillProfit</h3>
            <p>Empowering decisions through sentiment analysis</p>
          </div>
          <div className="footer-section">
            <h3>Quick Links</h3>
            <ul>
              <li><a href="https://merchant.razorpay.com/policy/OjfeGMULCsSlJB/privacy">Privacy Policy</a></li>
              <li><a href="https://merchant.razorpay.com/policy/OjfeGMULCsSlJB/terms">Terms and Conditions</a></li>
              <li><a href="https://merchant.razorpay.com/policy/OjfeGMULCsSlJB/refund">Cancellation and Refund Policy</a></li>
<li><a href="#https://merchant.razorpay.com/policy/OjfeGMULCsSlJB/contact_us">Contact Us</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Connect</h3>
            <div className="social-icons">
              <a href="#" aria-label="Facebook"><FaFacebookF /></a>
              <a href="#" aria-label="Twitter"><FaTwitter /></a>
              <a href="#" aria-label="LinkedIn"><FaLinkedinIn /></a>
              <a href="#" aria-label="GitHub"><FaGithub /></a>
            </div>
          </div>
          <div className="footer-section">
            <h3>Newsletter</h3>
            <form className="newsletter-form">
              <input type="email" placeholder="Enter your email" aria-label="Email for newsletter" />
              <button type="submit" aria-label="Subscribe">Subscribe</button>
            </form>
          </div>
        </div>
      </footer>
      {showTutorial && (
        <div className="tutorial" aria-live="polite">
          <div className="tutorial-content">
            <h2>Welcome to StillProfit!</h2>
            <p>Here's a quick guide to get you started:</p>
            <ol>
              <li>Choose your input type: News, Text, or URL.</li>
              <li>Enter your query or content in the input field.</li>
              <li>Click "Analyze" to see the sentiment analysis results.</li>
              <li>Explore the dashboard to understand the sentiment breakdown.</li>
            </ol>
            <button onClick={() => setShowTutorial(false)}>Got it!</button>
          </div>
        </div>
      )}
      <button className="help-button" onClick={() => setShowTutorial(true)} aria-label="Show tutorial">
        <FaQuestionCircle />
      </button>

      {showAuthPopup && (
        <div className="auth-popup">
        <div className="auth-popup-content">
          <h2>Welcome to StillProfit</h2>
          <p>Please sign in or sign up to continue.</p>
          <button onClick={() => navigate('/signin')}>Sign In</button>
          <button onClick={() => navigate('/signup')}>Sign Up</button>
          <button onClick={() => setShowAuthPopup(false)}>Close</button>
        </div>
      </div>
      )}
    </div>
    </ErrorBoundary>
  );
}

function AppWrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<RegistrationForm />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/" element={<App />} />
      </Routes>
    </Router>
  );
}

export default AppWrapper;
