import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { ref, set, get } from 'firebase/database';
import { auth, db } from './firebase';
import {
  Form,
  Input,
  Label,
  Button,
  Select,
  Checkbox,
  GlobalStyle,
  Card,
  Logo,
  Title,
  Subtitle,
  InputGroup,
  Icon,
  LinkText,
  StyledLink,
} from './SharedAuthStyles';
import { FaUser, FaEnvelope, FaLock, FaEye, FaEyeSlash, FaArrowRight, FaPhone, FaTag, FaExclamationCircle, FaSpinner } from 'react-icons/fa';
import BackButton from './BackButton';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';
import Joyride from 'react-joyride';
import ReactGA from 'react-ga';
import InputMask from 'react-input-mask';
import ErrorDisplay from './ErrorDisplay';
import SkeletonLoader from './SkeletonLoader';
import TermsModal from './TermsModal';

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  margin-bottom: 20px;
`;

const ProgressIndicator = styled.div`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: #6C63FF;
  transition: width 0.3s ease-in-out;
`;

const StepIndicator = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Step = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${props => props.active ? '#6C63FF' : '#e0e0e0'};
  color: ${props => props.active ? 'white' : 'black'};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 0.9em;
  margin-top: 5px;
`;

const CouponContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const PriceContainer = styled.div`
  background-color: #2A2A40;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const PriceLabel = styled.span`
  color: #B0B0B0;
`;

const Price = styled.span`
  color: #FFFFFF;
  font-weight: bold;
`;

const LaunchPrice = styled(Price)`
  color: #6C63FF;
  font-size: 1.2em;
`;

const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const TermsLink = styled.a`
  color: #6C63FF;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const phoneRegExp = /^(\+?\d{1,3}[- ]?)?\d{10}$/;

const schemaStep1 = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[^a-zA-Z0-9]/, 'Password must contain at least one special character'),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
});

const schemaStep2 = yup.object().shape({
  source: yup.string().required('Source is required'),
  couponCode: yup.string(),
  agreeTerms: yup.boolean().oneOf([true], 'You must agree to the terms'),
});

const RegistrationForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponError, setCouponError] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [finalPrice, setFinalPrice] = useState(19900); // Initial price in paise
  const [user, setUser] = useState(null);
  const [userSubscription, setUserSubscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [showTermsModal, setShowTermsModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register: registerStep1,
    handleSubmit: handleSubmitStep1,
    formState: { errors: errorsStep1 },
    watch,
  } = useForm({
    resolver: yupResolver(schemaStep1),
  });

  const {
    register: registerStep2,
    handleSubmit: handleSubmitStep2,
    formState: { errors: errorsStep2 },
  } = useForm({
    resolver: yupResolver(schemaStep2),
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const userRef = ref(db, 'users/' + currentUser.uid);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          setUserSubscription(snapshot.val().subscriptionActive);
        } else {
          setUserSubscription(false);
        }
      } else {
        setUserSubscription(null);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const applyCoupon = async () => {
    setCouponError('');
    setLoading(true);
    try {
      const response = await fetch('/apply-coupon', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ couponCode }),
      });
      const result = await response.json();
      if (result.valid) {
        setCouponApplied(true);
        setFinalPrice(result.discountedPrice);
      } else {
        setCouponApplied(false);
        setCouponError('Invalid coupon code');
      }
    } catch (error) {
      setCouponError('Error applying coupon');
    } finally {
      setLoading(false);
    }
  };

  const sendWelcomeEmail = async (email, name) => {
    try {
      await fetch('/api/send-welcome-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, name }),
      });
    } catch (error) {
      console.error('Error sending welcome email:', error);
    }
  };

  const onSubmitStep1 = async (data) => {
    if (!captchaToken) {
      setCouponError('Please complete the CAPTCHA');
      return;
    }
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
      const user = userCredential.user;

      await set(ref(db, 'users/' + user.uid), {
        name: data.name,
        email: data.email,
        phone: data.phone,
        paymentStatus: 'pending',
        subscriptionActive: false,
      });

      setFormData(data);
      setStep(2);
      ReactGA.event({
        category: 'User',
        action: 'Created an Account',
      });
    } catch (error) {
      console.error('Registration error:', error);
      alert('Failed to create account: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitStep2 = async (data) => {
    setLoading(true);
    const finalData = { ...formData, ...data };
    try {
      const user = auth.currentUser;

      if (!user) {
        throw new Error('User not authenticated');
      }

      const options = {
        key: 'rzp_live_an6176yJ9kJHAZ',
        amount: finalPrice,
        currency: 'INR',
        name: 'StillProfit',
        description: 'Subscription Payment',
        handler: async function (response) {
          try {
            await set(ref(db, 'users/' + user.uid), {
              ...finalData,
              paymentId: response.razorpay_payment_id,
              paymentStatus: 'completed',
              subscriptionActive: true,
            });

            const snapshot = await get(ref(db, 'users/' + user.uid));
            if (snapshot.exists() && snapshot.val().subscriptionActive) {
              await sendWelcomeEmail(finalData.email, finalData.name);
              alert('Payment successful! You can now access the app.');
              navigate('/');
            } else {
              throw new Error('User data not saved correctly');
            }
          } catch (error) {
            console.error('Error updating user data:', error);
            alert('Payment recorded, but there was an error updating your account. Please contact support.');
          } finally {
            setLoading(false);
          }
        },
        prefill: {
          name: finalData.name,
          email: finalData.email,
          contact: finalData.phone,
        },
        theme: {
          color: '#6C63FF',
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Payment initiation error:', error);
      alert('Failed to initiate payment: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const checkPasswordStrength = (password) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    if (strongRegex.test(password)) {
      setPasswordStrength('Strong');
    } else if (mediumRegex.test(password)) {
      setPasswordStrength('Medium');
    } else {
      setPasswordStrength('Weak');
    }
  };

  useEffect(() => {
    const password = watch('password');
    checkPasswordStrength(password);
  }, [watch('password')]);

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  return (
    <>
      <GlobalStyle />
      <BackButton />
      <Card>
        <ProgressBar>
          <ProgressIndicator progress={step === 1 ? 50 : 100} />
        </ProgressBar>
        <StepIndicator>
          <Step active={step >= 1}>1</Step>
          <Step active={step >= 2}>2</Step>
        </StepIndicator>
        <Joyride
          steps={[
            {
              target: '.step1',
              content: t('joyride.step1'),
            },
            {
              target: '.step2',
              content: t('joyride.step2'),
            },
          ]}
        />
        {step === 1 && (
          <Form onSubmit={handleSubmitStep1(onSubmitStep1)}>
            <Logo>S</Logo>
            <Title>{t('Create Your Account')}</Title>
            <Subtitle>{t('Join StillProfit')}</Subtitle>

            <InputGroup>
              <Label htmlFor="name">{t('Name')}</Label>
              <Input type="text" id="name" aria-label="Name" aria-required="true" {...registerStep1('name')} />
              {errorsStep1.name && <ErrorDisplay message={errorsStep1.name.message} />}
              <Icon>
                <FaUser />
              </Icon>
            </InputGroup>

            <InputGroup>
              <Label htmlFor="email">{t('Email')}</Label>
              <Input type="email" id="email" aria-label="Email" aria-required="true" {...registerStep1('email')} />
              {errorsStep1.email && <ErrorDisplay message={errorsStep1.email.message} />}
              <Icon>
                <FaEnvelope />
              </Icon>
            </InputGroup>

            <InputGroup>
              <Label htmlFor="password">{t('Password')}</Label>
              <Input type={showPassword ? 'text' : 'password'} id="password" aria-label="Password" aria-required="true" {...registerStep1('password')} />
              {errorsStep1.password && <ErrorDisplay message={errorsStep1.password.message} />}
              <Icon onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </Icon>
              {passwordStrength && <SuccessMessage>Password Strength: {passwordStrength}</SuccessMessage>}
            </InputGroup>

            <InputGroup>
              <Label htmlFor="phone">{t('Phone Number')}</Label>
              <Input type="tel" id="phone" aria-label="Phone Number" aria-required="true" {...registerStep1('phone')} />
              {errorsStep1.phone && <ErrorDisplay message={errorsStep1.phone.message} />}
              <Icon>
                <FaPhone />
              </Icon>
            </InputGroup>

            <ReCAPTCHA
              sitekey="6LcpmCkqAAAAANhFpk7wOLKI83_e55Zw4cHLuRmD"
              onChange={onCaptchaChange}
            />

            <Button type="submit" disabled={loading}>
              {loading ? <FaSpinner /> : t('Create Account')} <FaArrowRight />
            </Button>
          </Form>
        )}

        {step === 2 && (
          <Form onSubmit={handleSubmitStep2(onSubmitStep2)}>
            <Logo>S</Logo>
            <Title>{t('Additional Information')}</Title>
            <Subtitle>{t('Provide Details')}</Subtitle>

            <InputGroup>
              <Label htmlFor="source">{t('How Did You Hear')}</Label>
              <Select id="source" aria-label="Source" aria-required="true" {...registerStep2('source')}>
                <option value="">{t('select')}</option>
                <option value="google">{t('google')}</option>
                <option value="social">{t('socialMedia')}</option>
                <option value="friend">{t('friends')}</option>
                <option value="other">{t('other')}</option>
              </Select>
              {errorsStep2.source && <ErrorDisplay message={errorsStep2.source.message} />}
            </InputGroup>

            <InputGroup>
              <Label htmlFor="couponCode">{t('Apply Coupon Code')}</Label>
              <CouponContainer>
                <Input
                  type="text"
                  id="couponCode"
                  aria-label="Coupon Code"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  placeholder={t('Enter Coupon Code')}
                />
                <Button onClick={applyCoupon} disabled={loading}>
                  {loading ? <FaSpinner /> : <FaTag style={{ marginRight: '5px' }} />} {t('applyCoupon')}
                </Button>
              </CouponContainer>
              {couponError && (
                <ErrorDisplay message={couponError} />
              )}
              {couponApplied && <SuccessMessage>{t('Coupon Applied Successfully')}</SuccessMessage>}
            </InputGroup>

            <PriceContainer>
              <PriceRow>
                <PriceLabel>{t('MRP')}:</PriceLabel>
                <Price>Rs 199</Price>
              </PriceRow>
              <PriceRow>
                <PriceLabel>{t('Launching Price')}:</PriceLabel>
                <LaunchPrice>Rs 199</LaunchPrice>
              </PriceRow>
            </PriceContainer>

            <TermsContainer>
              <Checkbox type="checkbox" id="terms" aria-label="Agree to Terms" {...registerStep2('agreeTerms')} />
              <Label htmlFor="terms">
                {t('I Agree To The')} <TermsLink href="#" onClick={() => setShowTermsModal(true)}>{t('Terms And Conditions')}</TermsLink>
              </Label>
              {errorsStep2.agreeTerms && <ErrorDisplay message={errorsStep2.agreeTerms.message} />}
            </TermsContainer>

            <Button type="submit" disabled={loading}>
              {loading ? <FaSpinner /> : t('Proceed To Pay')} <FaArrowRight />
            </Button>
          </Form>
        )}
      </Card>
      <TermsModal isOpen={showTermsModal} onRequestClose={() => setShowTermsModal(false)} />
    </>
  );
};

export default RegistrationForm;
