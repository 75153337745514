import React from 'react';
import styled from 'styled-components';

const SkeletonContainer = styled.div`
  background: #e0e0e0;
  border-radius: 4px;
  height: 16px;
  margin-bottom: 10px;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.6;
    }
  }
`;

const SkeletonLoader = ({ width }) => {
  return <SkeletonContainer style={{ width }} />;
};

export default SkeletonLoader;
