import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { ref, get, set } from 'firebase/database';
import { auth, db } from './firebase';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash, FaArrowRight, FaTag, FaGoogle, FaSpinner } from 'react-icons/fa';
import {
  GlobalStyle, Card, Logo, Title, Subtitle, Form, InputGroup, Label, Input, Icon, RememberForgot, Checkbox, Button, LinkText, StyledLink, ErrorMessage, Select, PriceContainer, PriceRow, PriceLabel, Price, LaunchPrice, TermsContainer, TermsLink,
} from './SharedAuthStyles';
import BackButton from './BackButton';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import ErrorDisplay from './ErrorDisplay';
import SkeletonLoader from './SkeletonLoader';

const SignInPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPaymentStep, setShowPaymentStep] = useState(false);
  const [userData, setUserData] = useState(null);
  const [captchaToken, setCaptchaToken] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const googleProvider = new GoogleAuthProvider();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaToken) {
      setError('Please complete the CAPTCHA');
      return;
    }
    setError('');
    setIsLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Check user data in Realtime Database
      const userRef = ref(db, 'users/' + user.uid);
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        const userData = snapshot.val();
        setUserData(userData);

        if (userData.subscriptionActive) {
          navigate('/');
        } else {
          setShowPaymentStep(true);
        }
      } else {
        setError('User data not found. Please contact support.');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    if (!captchaToken) {
      setError('Please complete the CAPTCHA');
      return;
    }
    setError('');
    setIsLoading(true);

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      // Check user data in Realtime Database
      const userRef = ref(db, 'users/' + user.uid);
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        const userData = snapshot.val();
        setUserData(userData);

        if (userData.subscriptionActive) {
          navigate('/');
        } else {
          setShowPaymentStep(true);
        }
      } else {
        setError('User data not found. Please contact support.');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePayment = async () => {
    try {
      const user = auth.currentUser;

      if (!user) {
        throw new Error('User not authenticated');
      }

      const options = {
        key: 'rzp_live_an6176yJ9kJHAZ',
        amount: 19900, // Update this with your actual price
        currency: 'INR',
        name: 'StillProfit',
        description: 'Subscription Payment',
        handler: async function (response) {
          try {
            // Update user data with payment information
            await set(ref(db, 'users/' + user.uid), {
              ...userData,
              paymentId: response.razorpay_payment_id,
              paymentStatus: 'completed',
              subscriptionActive: true,
            });

            // Send thank you email
            await fetch('/api/send-thank-you-email', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ email: userData.email, name: userData.name }),
            });
            
            alert('Payment successful! You can now access the app.');
            navigate('/');
          } catch (error) {
            console.error('Error updating user data:', error);
            alert('Payment recorded, but there was an error updating your account. Please contact support.');
          }
        },
        prefill: {
          name: userData.name,
          email: userData.email,
          contact: userData.phone,
        },
        theme: {
          color: '#6C63FF',
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Payment initiation error:', error);
      alert('Failed to initiate payment: ' + error.message);
    }
  };

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  return (
    <>
      <GlobalStyle />
      <BackButton />
      <Card>
        <Logo>S</Logo>
        {!showPaymentStep ? (
          <>
            <Title>{t('SignIn To Your Account')}</Title>
            <Subtitle>{t('Welcome Back!')}</Subtitle>
            {error && <ErrorDisplay message={error} />}
            <Form onSubmit={handleSubmit}>
              <InputGroup>
                <Label htmlFor="email">{t('Email Address')}</Label>
                <Input
                  type="email"
                  id="email"
                  aria-label="Email Address"
                  placeholder={t('Enter Your Email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <Icon>
                  <FaEnvelope />
                </Icon>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="password">{t('Password')}</Label>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  aria-label="Password"
                  placeholder={t('Enter Your Password')}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <Icon onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </Icon>
              </InputGroup>
              <RememberForgot>
                <label>
                  <Checkbox
                    type="checkbox"
                    id="RememberMe"
                    aria-label="Remember Me"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  {t('Remember Me')}
                </label>
                <StyledLink to="/forgot-password">{t('Forgot Password')}</StyledLink>
              </RememberForgot>
              <ReCAPTCHA
                sitekey="6LcpmCkqAAAAANhFpk7wOLKI83_e55Zw4cHLuRmD"
                onChange={onCaptchaChange}
              />
              <Button type="submit" disabled={isLoading}>
                {isLoading ? <FaSpinner /> : t('Sign In')}
                {!isLoading && <FaArrowRight style={{ marginLeft: '10px' }} />}
              </Button>
            </Form>
            <LinkText>
              {t("Don't Have An Account")} <StyledLink to="/signup">{t('SignUp')}</StyledLink>
            </LinkText>
          </>
        ) : (
          <>
            <Title>{t('Complete Your Payment')}</Title>
            <Subtitle>{t('Complete Payment To Access')}</Subtitle>
            <PriceContainer>
              <PriceRow>
                <PriceLabel>{t('Subscription Price')}:</PriceLabel>
                <LaunchPrice>Rs 199</LaunchPrice>
              </PriceRow>
            </PriceContainer>
            <Button onClick={handlePayment}>
              {t('Proceed To Pay')} <FaArrowRight />
            </Button>
          </>
        )}
      </Card>
    </>
  );
};

export default SignInPage;
