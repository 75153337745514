import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaArrowLeft } from 'react-icons/fa';

const BackButtonStyled = styled.button`
  background: none;
  border: none;
  color: #6c63ff;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <BackButtonStyled onClick={() => navigate(-1)}>
      <FaArrowLeft style={{ marginRight: '5px' }} /> Back
    </BackButtonStyled>
  );
};

export default BackButton;