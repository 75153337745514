import React from 'react';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  color: #FF3860;
  background-color: #FEECF0;
  border: 1px solid #FF3860;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
`;

const ErrorDisplay = ({ message }) => {
  if (!message) return null;
  return <ErrorContainer>{message}</ErrorContainer>;
};

export default ErrorDisplay;
